import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@mui/material';
import {
  GET_CASE_BANNER_INFORMATION,
  SET_CASE_ID,
} from 'src/actions/caseActions';
import CaseBannerView from 'src/views/case/CaseBanner/CaseBannerView';
import { useGetCaseBannerInfoQuery } from 'src/store/serverState';
import LoadingScreen from 'src/components/LoadingScreen';
import CaseBannerMobile from 'src/views/case/CaseBanner/CaseBannerMobile';
import { Show } from 'src/components';
import MainLayout from '../MainLayout';
import ThirdTopBar from '../MainLayout/ThirdTopBar';
import SecondaryTopBar from '../MainLayout/SecondaryTopBar';
import CasePopUps from './CasePopUps';
import RefreshRecentCase from './RefreshRecentCase';

function CaseLayout({ transmit }) {
  const theme = useTheme();
  const { caseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const banner = useGetCaseBannerInfoQuery(caseId);
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (!banner.isFetching) {
      dispatch({
        type: SET_CASE_ID,
        payload: banner?.data?.id,
      });
      dispatch({
        type: GET_CASE_BANNER_INFORMATION,
        payload: banner?.data,
      });
    }
  }, [banner.isFetching]);

  if (banner.isLoading) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  if (banner.isError) {
    navigate('/404');
    return null;
  }

  if (transmit) {
    document.querySelector('html').style.overflow = 'auto';
    return (
      <div style={{ width: '100%' }}>
        <Outlet />
      </div>
    );
  }

  return (
    <MainLayout>
      <Show when={mobileView}>
        <CaseBannerMobile banner={banner.data} />
      </Show>
      <div
        style={{
          display: 'flex',
          minHeight: 0,
          height: '100%',
        }}
      >
        <Show when={!mobileView}>
          <CaseBannerView banner={banner.data} />
        </Show>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'auto',
            overflowY: 'hidden',
            width: '100%',
          }}
        >
          <Show when={!mobileView}>
            <SecondaryTopBar />
            <ThirdTopBar />
          </Show>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              overflow: 'auto',
            }}
          >
            {banner.isFetching ? <LoadingScreen /> : <Outlet />}
          </div>
        </div>
      </div>
      <RefreshRecentCase caseId={caseId} />

      <CasePopUps caseId={caseId} />
    </MainLayout>
  );
}

export default CaseLayout;
