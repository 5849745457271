import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const baseURL = '/public/serology';

class RecoveryService {
  getSerology = async (caseId, serologyId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/${serologyId}`);
      if (request.status === 200) {
        return request.data.serology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createSerology = async (caseId, payload) => {
    try {
      const request = await axios.post(`${baseURL}/${caseId}`, payload);
      if (request.status === 200) {
        return request.data.serology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateSerology = async (caseId, serologyId, payload) => {
    try {
      const request = await axios.put(
        `${baseURL}/${caseId}/${serologyId}`,
        payload,
      );
      if (request.status === 200) {
        return request.data.serology;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const recoveryService = new RecoveryService();

export default recoveryService;
