import { useState, useEffect } from 'react';
import { useFieldValue } from 'formik';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

function CustomForm({
  formIdentifier,
  fieldIdentifier,
  dateCheckField,
  ...props
}) {
  const [customText, setCustomText] = useState(null);
  const [createdDate] = useFieldValue(dateCheckField);

  useEffect(() => {
    const fetchCustomForm = async () => {
      try {
        const request = await axios.get('public/customForm', {
          params: {
            formIdentifier,
            fieldIdentifier,
            createdDate,
          },
        });
        if (request.status === 200) {
          return setCustomText(request?.data?.record?.fieldText);
        }
        Sentry.captureException(request.data.msg);
        throw new Error(request.data.msg);
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };
    fetchCustomForm();
  }, []);

  return (
    <>
      {customText &&
        customText.split('\n').map((line, i) => {
          const leadingTabs = Math.round(line.search(/\S|$/) / 2); // 2 spaces per tab
          return (
            <Typography
              {...props}
              key={i}
              style={{ marginLeft: `${leadingTabs}em` }}
            >
              {line}
            </Typography>
          );
        })}
    </>
  );
}

CustomForm.propTypes = {
  formIdentifier: PropTypes.string.isRequired,
  fieldIdentifier: PropTypes.string.isRequired,
  dateCheckField: PropTypes.string.isRequired,
};

export default CustomForm;
