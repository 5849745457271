import { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setCaseId, openDuplicateCaseWarning } from 'src/actions/caseActions';
import { openDodDialog, setDodOverrideFalse } from 'src/actions/donorActions';
import { clearSupplyValidation } from 'src/actions/recoveryActions';
import { Formik, Form } from 'formik';
import moment from 'moment';
import Page from 'src/components/Page';
import caseService from 'src/services/caseService';
import recoveryService from 'src/services/recoveryService';
import medSocialService from 'src/services/medSocialService';
import medicalReviewService from 'src/services/medicalReviewService';
import settingService from 'src/services/settingService';
import attachmentsService from 'src/services/attachmentsService';
import serologyService from 'src/services/serologyService';
import adminService from 'src/services/adminService';
import approvalsService from 'src/services/approvalsService';
import LoadingScreen from 'src/components/LoadingScreen';
import { useParams, useNavigate, Prompt } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Modes, BeforeUnload } from 'src/components';
import { setMode } from 'src/actions/formPermissionsActions';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import * as Sentry from '@sentry/browser';

import DeniedScreen from 'src/components/DeniedScreen';
import roleService from 'src/services/roleService';
import apiKeyService from 'src/services/apiKeyService';
import userService from 'src/services/userService';
import ApiKeyDialog from 'src/views/admin/ApiKeyView/ApiKeyDialog';
import authFormService from 'src/services/authFormService';
import OfflineView from 'src/views/pages/OfflineView';
import { captureException } from '@sentry/browser';
import isMonthYearCurrent from 'src/utils/isMonthYearCurrent';
import {
  serverState,
  useAddCaseContactsMutation,
  useAddDonorScreeningMutation,
  useAddReferralWorksheetMutation,
  useLazyGetCaseSummaryQuery,
  useLazyGetCaseContactsQuery,
  useLazyGetDonorScreeningQuery,
  useLazyGetLookupsQuery,
  useLazyGetReferralWorksheetQuery,
  useUpdateCaseContactsMutation,
  useUpdateCaseSummaryMutation,
  useUpdateDonorScreeningMutation,
  useUpdateLookupsMutation,
  useUpdateReferralWorksheetMutation,
  useAddSerologyMutation,
  useUpdateSerologyMutation,
} from 'src/store/serverState';
import {
  caseSummaryValidation,
  caseWorksheetValidation,
  donorScreeningValidation,
  draiQuestionFormSchema,
  draiValidationSchema,
  lookupTableValidation,
  attachmentValidation,
  caseContactValidation,
} from './validation';

const useStyles = makeStyles((theme) => ({
  root: { height: '100%' },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  contentContainer: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  smallScreenContent: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '180px !important',
    },
  },
}));

function FormLayout({
  children,
  formId,
  pageName,
  permission: { readPermission, createPermission, updatePermission },
  readOnly,
  upgradedESign,
  previewType,
  previewId,
  initialValue,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    caseId,
    draiId: draiIdParam,
    serologyId: serologyIdParam,
    authFormId: authFormIdParam,
    roleId,
    userId,
    apiKeyId,
    activeOrganizationId,
    activeGroupId,
  } = useParams();
  const [caseSummary, caseSummaryData] = useLazyGetCaseSummaryQuery();
  const [updateCaseSummary] = useUpdateCaseSummaryMutation();

  const [referralWorksheet, referralWorksheetData] =
    useLazyGetReferralWorksheetQuery();
  const [updateReferralWorksheet] = useUpdateReferralWorksheetMutation();
  const [addReferralWorksheet] = useAddReferralWorksheetMutation();

  const [donorScreening, donorScreeningData] = useLazyGetDonorScreeningQuery();
  const [updateDonorScreening] = useUpdateDonorScreeningMutation();
  const [addDonorScreening] = useAddDonorScreeningMutation();

  const [caseContacts, caseContactsData] = useLazyGetCaseContactsQuery();
  const [updateCaseContacts] = useUpdateCaseContactsMutation();
  const [addCaseContacts] = useAddCaseContactsMutation();

  const [lookups, lookupsData] = useLazyGetLookupsQuery();
  const [updateLookups] = useUpdateLookupsMutation();

  const [addSerology] = useAddSerologyMutation();
  const [updateSerology] = useUpdateSerologyMutation();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [data, setData] = useState({});
  const [apiData, setApiData] = useState();
  const [onlySave, setOnlySave] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [isHidden, setIsHidden] = useState(readOnly);
  const [denied, setDenied] = useState(false);
  const [errorStatus, setErrorStatus] = useState({});
  const [validation, setValidation] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const formMode = useSelector((state) => state.formPermissions.mode);
  const currentDRAIForm = useSelector((state) => state.drai.currentDRAIForm);
  const currentAuthForm = useSelector(
    (state) => state.authForm.currentAuthForm,
  );

  const account = useSelector((state) => state.account);
  const banner = useSelector((state) => state.caseItem.banner);

  const [overrideWarning, setOverrideWarning] = useState(false);

  const draiId = previewType === 'DRAI' ? previewId : draiIdParam;
  const authFormId = previewType === 'AuthForm' ? previewId : authFormIdParam;
  const serologyId = previewType === 'serology' ? previewId : serologyIdParam;

  // for date of death dialog
  const { dodDialog } = useSelector((state) => state.donor);

  // for clearing supply kit valiation redux state when navigating away from tissues recovered
  const { dateValidationRows } = useSelector((state) => state.recovery);

  const getCalls = async (mode, values) => {
    switch (formId) {
      /* SUMMARY */
      case 'caseSummary': {
        switch (mode) {
          case 'create':
            return updateCaseSummary({
              caseId,
              ...values,
              clientDateTime: new Date(),
              clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }).unwrap();
          case 'update':
            return updateCaseSummary({
              caseId,
              ...values,
              clientDateTime: new Date(),
              clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }).unwrap();
          case 'read':
          default:
            setValidation(caseSummaryValidation);
            if (caseSummaryData.isError) {
              return null;
            }
            return caseSummary(caseId, true);
        }
      }

      case 'approvalsCaseLock': {
        switch (mode) {
          case 'create':
            return caseService.updateCaseLock(caseId, {
              ...values,
              createdDateTimezone:
                Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
          case 'update':
            return caseService.updateCaseLock(caseId, {
              ...values,
              createdDateTimezone:
                Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
          case 'read':
          default:
            return caseService.getCaseLock(caseId);
        }
      }

      /* WORKSHEET */
      case 'caseWorksheet': {
        switch (mode) {
          case 'create':
            return addReferralWorksheet(values).unwrap();
          case 'update':
            return updateReferralWorksheet({ caseId, ...values }).unwrap();
          case 'read':
          default:
            setValidation(caseWorksheetValidation);
            if (referralWorksheetData.isError) {
              return null;
            }
            return referralWorksheet(caseId, true);
        }
      }

      /* SCREENING */
      case 'donorScreening': {
        switch (mode) {
          case 'create':
            return addDonorScreening({ caseId, ...values }).unwrap();
          case 'update':
            return updateDonorScreening({ caseId, ...values }).unwrap();
          case 'read':
          default:
            setValidation(donorScreeningValidation);
            if (donorScreeningData.isError) {
              return null;
            }
            return donorScreening(caseId, true);
        }
      }

      /* CASE CONTACTS */
      case 'caseContacts': {
        switch (mode) {
          case 'create':
            return addCaseContacts({ caseId, ...values }).unwrap();
          case 'update':
            return updateCaseContacts({ caseId, ...values }).unwrap();
          case 'read':
          default:
            setValidation(caseContactValidation);
            if (caseContactsData.isError) {
              return null;
            }
            return caseContacts(caseId, true);
        }
      }

      /* CASE CONTACTS */
      case 'recoveryShippingDetails': {
        switch (mode) {
          case 'create':
            return recoveryService.updateShippingDetails(caseId, values);
          case 'update':
            return recoveryService.updateShippingDetails(caseId, values);
          case 'read':
          default:
            return recoveryService.getShippingDetails(caseId);
        }
      }

      /* CASE - PRELIMINARY HEMODILUTION */
      case 'casePreliminaryHemodilution': {
        switch (mode) {
          case 'create':
            return caseService.updatePreliminaryHemodilutionInformation(
              caseId,
              values,
            );
          case 'update':
            return caseService.updatePreliminaryHemodilutionInformation(
              caseId,
              values,
            );
          case 'read':
          default: {
            // Turning off validation for now.
            // Validation only achieves making it impossible to save the hemodilution page.
            // setValidation(hemodilutionValidation);
            return caseService.getPreliminaryHemodilutionInformation(caseId);
          }
        }
      }

      /* RECOVERY - DONOR INFORMATION */
      case 'recoveryDonorInformation': {
        switch (mode) {
          case 'create':
            return recoveryService.updateDonorInformation(caseId, values);
          case 'update':
            return recoveryService.updateDonorInformation(caseId, values);
          case 'read':
          default:
            return recoveryService.getDonorInformation(caseId);
        }
      }

      /* RECOVERY - RECOVERY SITE */
      case 'recoveryRecoverySite': {
        switch (mode) {
          case 'create':
            return recoveryService.createRecoverySite(caseId, values);
          case 'update':
            return recoveryService.updateRecoverySite(caseId, values);
          case 'read':
          default:
            return recoveryService.getRecoverySite(caseId);
        }
      }

      /* RECOVERY - EYE ASSESSMENT */
      case 'recoveryEyeAssessment': {
        switch (mode) {
          case 'create':
            return recoveryService.updateEyeAssessment(caseId, values);
          case 'update':
            return recoveryService.updateEyeAssessment(caseId, values);
          case 'read':
          default:
            return recoveryService.getEyeAssessment(caseId);
        }
      }

      /* RECOVERY - PHYSICAL ASSESSMENT */
      case 'recoveryPhysicalAssessment': {
        switch (mode) {
          case 'create':
            return recoveryService.updatePhysicalAssessment(caseId, values);
          case 'update':
            return recoveryService.updatePhysicalAssessment(caseId, values);
          case 'read':
          default:
            return recoveryService.getPhysicalAssessment(caseId);
        }
      }

      /* RECOVERY - TISSUES RECOVERED */
      case 'recoveryTissuesRecovered': {
        switch (mode) {
          case 'create':
            return recoveryService.createTissueRecovery(caseId, values);
          case 'update':
            return recoveryService.updateTissueRecovery(caseId, values);
          case 'read':
          default:
            return recoveryService.getTissueRecovery(caseId);
        }
      }

      /* SEROLOGY */
      // FIXME: Please use seperate routes!
      case 'recoverySerology':
      case 'medicalReviewSerology': {
        switch (mode) {
          case 'create':
            return addSerology({ caseId, ...values }).unwrap();
          case 'update':
            return updateSerology({ caseId, serologyId, ...values }).unwrap();
          case 'read':
          default:
            return serologyService.getSerology(caseId, serologyId);
        }
      }

      /* MED SOCIAL - TISSUES RECOVERED */
      case 'medSocial': {
        switch (mode) {
          case 'create':
            return medSocialService.createDrai(caseId, values);
          case 'update':
            return medSocialService.updateDrai(caseId, draiId, values);
          case 'read':
          default: {
            if (draiId === 'new') {
              const draiData = await medSocialService.getBlankDrai({
                caseId,
                type: currentDRAIForm?.value,
              });
              if (draiData?.type || currentDRAIForm?.value) {
                setValidation(
                  draiValidationSchema.concat(
                    draiQuestionFormSchema({
                      type: draiData?.type || currentDRAIForm?.value,
                      fieldVersions: draiData?.version,
                    }),
                  ),
                );
              }
              draiData.interviewerFullName = `${draiData.interviewerFirstName} ${draiData.interviewerLastName}`;
              return draiData;
            }
            const draiData = await medSocialService.getDrai(caseId, draiId);
            if (draiData?.type || currentDRAIForm?.value) {
              setValidation(
                draiValidationSchema.concat(
                  draiQuestionFormSchema({
                    type: draiData?.type || currentDRAIForm?.value,
                    fieldVersions: draiData?.version,
                  }),
                ),
              );
            }
            draiData.interviewerFullName = `${draiData.interviewerFirstName} ${draiData.interviewerLastName}`;
            return draiData;
          }
        }
      }

      /* Auth Form */
      case 'authForm': {
        switch (mode) {
          case 'create':
            return authFormService.createAuthForm(caseId, values);
          case 'update':
            return authFormService.updateAuthForm(caseId, authFormId, values);
          case 'read':
          default: {
            const authFormData = await authFormService.getAuthForm(
              caseId,
              authFormId,
            );
            if (authFormData?.type || currentAuthForm?.value) {
              setValidation(
                draiValidationSchema.concat(
                  draiQuestionFormSchema(
                    authFormData?.type || currentAuthForm?.value,
                  ),
                ),
              );
            }
            authFormData.interviewerFullName = `${authFormData.interviewerFirstName} ${authFormData.interviewerLastName}`;
            return authFormData;
          }
        }
      }

      /* MED REVIEW - CULTURE RESULTS */
      case 'medReview': {
        switch (mode) {
          case 'create':
            return medicalReviewService.updateCultureResults(caseId, {
              ...values,
              timeStamp: moment().format('MM/DD/YYYY HH:mm'),
            });
          case 'update':
            return medicalReviewService.updateCultureResults(caseId, {
              ...values,
              timeStamp: moment().format('MM/DD/YYYY HH:mm'),
            });
          case 'read':
          default:
            return medicalReviewService.getCultureResults(caseId);
        }
      }
      case 'medicalReviewPathology': {
        switch (mode) {
          case 'create':
            return medicalReviewService.createPathology(caseId, values);
          case 'update':
            return medicalReviewService.updatePathology(caseId, values);
          case 'read':
          default:
            return medicalReviewService.getPathology(caseId);
        }
      }

      /* MED REVIEW - HEMODILUTION */
      case 'medicalReviewHemodilution': {
        switch (mode) {
          case 'create':
            return medicalReviewService.updateHemodilutionInformation(
              caseId,
              values,
            );
          case 'update':
            return medicalReviewService.updateHemodilutionInformation(
              caseId,
              values,
            );
          case 'read':
          default: {
            // Turning off validation for now.
            // Validation only achieves making it impossible to save the hemodilution page.
            // setValidation(hemodilutionValidation);
            return medicalReviewService.getHemodilutionInformation(caseId);
          }
        }
      }

      case 'medicalReviewSummary': {
        switch (mode) {
          case 'create':
          case 'update':
            return medicalReviewService.upsertMedicalReviewSummary(
              caseId,
              values,
            );
          case 'read':
          default: {
            return medicalReviewService.getMedicalReviewSummary(caseId);
          }
        }
      }

      case 'cbcTempMeds': {
        switch (mode) {
          case 'create':
            return medicalReviewService.upsertCbcTempMeds(caseId, values);
          case 'update':
            return medicalReviewService.upsertCbcTempMeds(caseId, values);
          case 'read':
          default:
            return medicalReviewService.getCbcTempMeds(caseId);
        }
      }

      /* SETTINGS - NOTIFICATIONS */
      case 'settings-notifications': {
        switch (mode) {
          case 'create':
          case 'update':
            return settingService.setNotificationSettings(values);
          case 'read':
          default: {
            return settingService.getNotificationSettings();
          }
        }
      }

      /* ADMIN - LOOKUP TABLES */
      case 'lookupTables': {
        switch (mode) {
          case 'create':
          case 'update':
            return updateLookups(values).unwrap();
          case 'read':
          default: {
            setValidation(lookupTableValidation);
            if (lookupsData.isError) {
              return null;
            }
            return lookups(null, true);
          }
        }
      }

      /* ADMIN - ROLE */
      case 'adminRole': {
        switch (mode) {
          case 'create':
            return roleService.updateRole(roleId, values);
          case 'update':
            return roleService.updateRole(roleId, values);
          case 'read':
          default: {
            return roleService.getRoleAdmin(roleId);
          }
        }
      }

      /* ADMIN - API KEY */
      case 'apiKey': {
        switch (mode) {
          case 'create':
            return apiKeyService.createApiKey(values);
          case 'update':
            return apiKeyService.updateApiKey(apiKeyId, values);
          case 'delete': {
            await apiKeyService.deleteApiKey(apiKeyId);
            return navigate('/admin/apiKeys');
          }
          case 'read':
          default: {
            return apiKeyService.getApiKey(apiKeyId);
          }
        }
      }

      /* ADMIN - USER */
      case 'adminUser': {
        switch (mode) {
          case 'create':
            return userService.createUser(values);
          case 'update':
            return userService.updateUser(userId, values);
          case 'read':
          default: {
            return userService.getUserAdmin(userId);
          }
        }
      }

      /* SELF SETTINGS */
      case 'selfSettings': {
        switch (mode) {
          case 'create':
          case 'update':
            return userService.updateSelf(values);
          case 'read':
          default: {
            return userService.getSelf();
          }
        }
      }

      /* ADMIN - REFERENCE TABLES */
      case 'referenceTables': {
        switch (mode) {
          case 'create':
          case 'update':
            return adminService.updateReferenceTables(values);
          case 'read':
          default: {
            const referenceTableData = await adminService.getReferenceTables();
            return referenceTableData;
          }
        }
      }
      case 'organizations': {
        switch (mode) {
          case 'create':
          case 'update':
            dispatch(serverState.util.invalidateTags(['Organizations']));
            return adminService.updateOrganizations(values);
          case 'read':
          default: {
            const referenceTableData =
              await adminService.getOrganizations(activeOrganizationId);
            return referenceTableData;
          }
        }
      }
      case 'groups': {
        switch (mode) {
          case 'create':
          case 'update':
            return adminService.updateGroups(values);
          case 'read':
          default: {
            const referenceTableData =
              await adminService.getGroups(activeGroupId);
            return referenceTableData;
          }
        }
      }
      /* Attachments */
      case 'attachments': {
        switch (mode) {
          case 'update':
            return attachmentsService.upsertAttachments(caseId, values);
          case 'read':
          default: {
            setValidation(attachmentValidation);
            return attachmentsService.getAttachments(caseId);
          }
        }
      }

      case 'approval': {
        switch (mode) {
          case 'create':
            return approvalsService.createApproval(caseId, values);
          case 'update':
            return approvalsService.updateApproval(caseId, values);
          case 'read':
          default: {
            return approvalsService.getApproval(caseId);
          }
        }
      }

      /* Reports */
      case 'reports': {
        switch (mode) {
          case 'create':
          case 'update':
          case 'read':
          default: {
            const reportData = [];
            return reportData;
          }
        }
      }

      case 'transmitData': {
        setIsHidden(true);
        return {};
      }

      default:
        return {};
    }
  };

  const handleDelete = () => {
    getCalls('delete');
  };

  const checkPermission = (permission) =>
    account?.user?.permissions && account.user.permissions.includes(permission);

  const loadSummary = async (overrideSave) => {
    let makeOnlySave = null;
    if (overrideSave != null) {
      makeOnlySave = overrideSave;
    } else {
      makeOnlySave = onlySave;
    }
    if (
      readPermission == null ||
      (readPermission && checkPermission(readPermission))
    ) {
      dispatch(setMode('view'));
      try {
        const callInfo = await getCalls('read');

        if (callInfo?.requestId) {
          if (callInfo?.isLoading) return;
          setData(callInfo.data);
        } else {
          setData(callInfo);
        }

        if (callInfo?.approved && upgradedESign) {
          dispatch(setMode('view'));
          setIsHidden(true);
        } else if (
          callInfo?.submitted != null &&
          callInfo?.submitted !== true
        ) {
          if (
            createPermission == null ||
            (createPermission && checkPermission(createPermission))
          ) {
            if (
              readOnly ||
              ((callInfo?.locked || banner?.locked) &&
                formId !== 'approvalsCaseLock' &&
                caseId)
            ) {
              dispatch(setMode('view'));
              setIsHidden(true);
            } else {
              dispatch(setMode('edit'));
              setIsHidden(false);
            }
          } else {
            dispatch(setMode('view'));
            setIsHidden(true);
          }
        } else if (
          updatePermission == null ||
          (updatePermission && checkPermission(updatePermission))
        ) {
          if (
            readOnly ||
            ((callInfo?.locked || banner?.locked || callInfo?.oldVersion) &&
              formId !== 'approvalsCaseLock' &&
              caseId)
          ) {
            dispatch(setMode('view'));
            setIsHidden(true);
          } else if (makeOnlySave) {
            dispatch(setMode('edit'));
            setIsHidden(false);
          } else {
            dispatch(setMode('view'));
            setIsHidden(false);
          }
        } else {
          dispatch(setMode('view'));
          setIsHidden(true);
        }
        if (
          readOnly ||
          ((callInfo?.locked || banner?.locked || callInfo?.oldVersion) &&
            formId !== 'approvalsCaseLock' &&
            caseId)
        ) {
          dispatch(setMode('view'));
          setIsHidden(true);
        }
        setIsLoading(false);
      } catch (err) {
        dispatch(setMode('view'));
        if (err?.response?.status) {
          setErrorStatus({
            status: err.response.status,
            linked: err.response.data,
            case: caseId,
          });
        } else {
          const errorId = captureException(err);
          setErrorStatus({
            status: 1001,
            case: caseId,
            linked: errorId,
          });
        }
        setIsLoading(false);
      }
    } else {
      setDenied(true);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (caseId) {
      setOnlySave(false);
      dispatch(setMode('view'));
      if (authFormId === 'new') {
        setOnlySave(true);
      } else if (authFormId) {
        dispatch(setMode('view'));
        setOnlySave(false);
      }
      if (draiId === 'new') {
        setOnlySave(true);
      } else if (draiId) {
        dispatch(setMode('view'));
        setOnlySave(false);
      }
      loadSummary(false);
    } else if (formId === 'caseWorksheet') {
      setValidation(caseWorksheetValidation);
      setData({ donorHeightUnit: 'cm', donorWeightUnit: 'kg' });
      dispatch(setMode('edit'));
      setOnlySave(true);
      setIsLoading(false);
    } else if (formId === 'apiKey') {
      if (apiKeyId) {
        setCanDelete(true);
        dispatch(setMode('view'));
        setOnlySave(false);
        loadSummary(false);
      } else {
        dispatch(setMode('edit'));
        setOnlySave(true);
        loadSummary(true);
      }
    } else if (formId === 'adminUser' && !userId) {
      dispatch(setMode('edit'));
      setOnlySave(true);
      setIsLoading(false);
    } else if (
      (formId === 'organizations' && activeOrganizationId) ||
      (formId === 'groups' && activeGroupId)
    ) {
      dispatch(setMode('view'));
      setOnlySave(true);
      loadSummary(true);
    } else if (
      (formId === 'organizations' && !activeOrganizationId) ||
      (formId === 'groups' && !activeGroupId)
    ) {
      dispatch(setMode('view'));
      setOnlySave(false);
      loadSummary(true);
      setIsHidden(true);
    } else if (
      [
        'reports',
        'adminRole',
        'selfSettings',
        'activeReferral',
        'transmitData',
      ].includes(formId) ||
      formId.startsWith('settings-')
    ) {
      dispatch(setMode('edit'));
      setOnlySave(true);
      loadSummary(true);
    } else if (['lookupTables', 'referenceTables'].includes(formId)) {
      dispatch(setMode('edit'));
      setOnlySave(false);
      loadSummary(true);
    } else if (['adminUser'].includes(formId)) {
      setOnlySave(false);
      dispatch(setMode('view'));
      loadSummary(false);
    } else {
      navigate('/404');
    }
  }, [
    banner.locked,
    readPermission,
    updatePermission,
    createPermission,
    caseId,
    draiId,
    currentDRAIForm,
    authFormId,
    apiKeyId,
    serologyId,
    caseSummaryData.isFetching,
    referralWorksheetData.isFetching,
    donorScreeningData.isFetching,
    caseContactsData.isFetching,
    lookupsData.isFetching,
  ]);

  if (errorStatus?.status) {
    return (
      <OfflineView
        noHome
        errorInformation={errorStatus}
      />
    );
  }
  if (denied) {
    return <DeniedScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (apiData) {
    return <ApiKeyDialog apiData={apiData} />;
  }

  return (
    <Page
      className={classes.root}
      title={pageName}
    >
      <Formik
        initialValues={initialValue || data || {}}
        enableReinitialize
        validationSchema={validation || Yup.object().shape({})}
        validateOnBlur={false}
        validateOnChange={false}
        // the next line is ignored because it doesn't require a return. dan approved.
        // eslint-disable-next-line
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // if (formVersionProp) {
            //   delete values.version;
            // }
            setSubmitting(true);
            if (banner?.locked && formId !== 'approvalsCaseLock' && caseId) {
              return enqueueSnackbar('Case is locked!', {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              });
            }
            // date of death dialog code
            if (
              (formId === 'caseWorksheet' ||
                formId === 'recoveryDonorInformation') &&
              values.donorDeathDate &&
              !isMonthYearCurrent(values.donorDeathDate) &&
              !dodDialog.dialogOverride &&
              moment(values.donorDeathDate).format('MM-DD-YYYY') !==
                moment(data?.donorDeathDate).format('MM-DD-YYYY')
            ) {
              return dispatch(openDodDialog());
            }

            if (data?.id != null || data?.submitted) {
              await getCalls('update', values);
              dispatch(setMode('view'));
              await loadSummary();
            } else if (formId === 'caseWorksheet' && !data.id) {
              const newCase = await getCalls('create', values);
              if (newCase?.duplicateCase) {
                return dispatch(openDuplicateCaseWarning(newCase));
              }
              if (newCase?.caseItem?.id) {
                dispatch(setCaseId(newCase?.caseItem.id));
                dispatch(setMode('view'));
                setOverrideWarning(true);
                navigate(`/case/${newCase?.caseItem.id}/referral/worksheet`);
              }
            } else if (formId === 'adminUser' && !data.id) {
              const newUser = await getCalls('create', values);
              if (newUser?.id) {
                dispatch(setMode('view'));
                setOverrideWarning(true);
                navigate(`/admin/users/${newUser.id}`);
              }
            } else if (formId === 'apiKey' && !data.id) {
              const newKey = await getCalls('create', values);
              if (newKey?.id) {
                dispatch(setMode('view'));
                window.history.pushState(
                  null,
                  'API Key Management',
                  `/admin/apiKeys/${newKey.id}`,
                );

                setApiData(newKey);
              }
            } else if (
              formId === 'recoverySerology' &&
              (serologyId === 'new' || !serologyId)
            ) {
              const newValues = await getCalls('create', {
                ...values,
                type: 'RECOVERY',
              });
              await dispatch(setMode('view'));
              setTimeout(() => {
                navigate(`/case/${caseId}/recovery/serologies/${newValues.id}`);
              }, 0);
            } else if (
              formId === 'medicalReviewSerology' &&
              (serologyId === 'new' || !serologyId)
            ) {
              const newValues = await getCalls('create', {
                ...values,
                type: 'MEDICAL_REVIEW',
              });

              await dispatch(setMode('view'));
              setTimeout(() => {
                navigate(
                  `/case/${caseId}/medicalReview/serologies/${newValues.id}`,
                );
              }, 0);
            } else if (formId === 'medSocial' && draiId === 'new') {
              const newValues = await getCalls('create', values);
              await dispatch(setMode('view'));
              setOverrideWarning(true);
              navigate(`/case/${caseId}/medSocial/${newValues.id}`);
            } else if (formId === 'authForm' && authFormId === 'new') {
              const newValues = await getCalls('create', values);
              await dispatch(setMode('view'));
              setOverrideWarning(true);
              navigate(
                `/case/${caseId}/referral/authorizations/${newValues.id}`,
              );
            } else {
              await getCalls('create', values);
              await dispatch(setMode('view'));
              await loadSummary();
            }
            dispatch(setDodOverrideFalse());
            if (Object.keys(dateValidationRows).length !== 0) {
              dispatch(clearSupplyValidation());
            }
            enqueueSnackbar('Successfully saved!', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });

            // We should remove this once everything is moved to RTK query
            // This helps ensure on updates that are not connected we refresh data
            dispatch(
              serverState.util.invalidateTags([
                'Case',
                'ReferralWorksheet',
                'Referrals',
              ]),
            );

            // getCaseOnLoad();
            // Make API request
            setStatus({ success: true });
            setSubmitting(false);
            setIsLoading(false);
          } catch (error) {
            dispatch(setDodOverrideFalse());
            if (error?.response?.data?.error === 'DUPLICATE_CASELOCK_STATUS') {
              enqueueSnackbar(error?.response?.data?.message, {
                variant: 'warning',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              });
              setStatus({ success: false });
              setErrors({ submit: error?.message || error?.data?.message });
              setSubmitting(false);
              setIsLoading(false);
              return null;
            }
            enqueueSnackbar('Something went wrong!', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });

            if (error?.response?.status) {
              const sentryError = Sentry.captureException(
                new Error(
                  `${error.response.status}: ${error.response.statusText}`,
                ),

                {
                  tags: {
                    status: error.response.status,
                    case: caseId,
                    linked: error.response.data,
                  },
                },
              );
              Sentry.showReportDialog({
                subtitle:
                  'Tell us what happened below without including any Protected Health Information.',
                subtitle2: '',
                labelSubmit: 'Submit Report',
                eventId: sentryError,
                user: account?.user?.firstName
                  ? {
                      name: `${account.user.firstName} ${account.user.lastName}`,
                      email: account.user.email,
                    }
                  : undefined,
              });
            } else {
              const sentryError = Sentry.captureException(new Error(error), {
                tags: {
                  case: caseId,
                },
              });
              Sentry.showReportDialog({
                subtitle:
                  'Tell us what happened below without including any Protected Health Information.',
                subtitle2: '',
                eventId: sentryError,
                labelSubmit: 'Submit Report',
                user: account?.user?.firstName
                  ? {
                      name: `${account.user.firstName} ${account.user.lastName}`,
                      email: account.user.email,
                    }
                  : undefined,
              });
            }
            setStatus({ success: false });
            setErrors({ submit: error?.message || error?.data?.message });
            setSubmitting(false);
            setIsLoading(false);
          }
        }}
      >
        {({ isSubmitting, resetForm, dirty, handleSubmit }) => (
          <>
            {!overrideWarning && dirty && formMode === 'edit' && (
              <>
                <Prompt
                  when={dirty && formMode === 'edit'}
                  message="You have unsaved changes. Are you sure you want to leave?"
                />

                <BeforeUnload
                  onBeforeUnload={() =>
                    'You have unsaved changes. Are you sure you want to leave?'
                  }
                />
              </>
            )}
            {isSubmitting && <LoadingScreen />}
            <Container maxWidth={false}>
              <Form
                noValidate
                autoComplete="off"
              >
                {!isHidden && !isSubmitting && (
                  <Modes
                    handleSubmit={handleSubmit}
                    onlySave={onlySave}
                    canDelete={canDelete}
                    handleDelete={handleDelete}
                    resetForm={resetForm}
                  />
                )}
                {children}
              </Form>
            </Container>
          </>
        )}
      </Formik>
    </Page>
  );
}

FormLayout.propTypes = {
  children: PropTypes.any,
  formId: PropTypes.string,
  pageName: PropTypes.string,
  permission: PropTypes.object,
  readOnly: PropTypes.bool,
  previewType: PropTypes.string,
  previewId: PropTypes.number,
  initialValue: PropTypes.object,
  upgradedESign: PropTypes.bool,
};

FormLayout.defaultProps = {
  permission: {
    readPermission: null,
    createPermission: null,
    updatePermission: null,
  },
  readOnly: false,
  previewType: 'none',
};

export default FormLayout;
