/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import { useFieldValue } from 'formik';
import { Box, Typography } from '@mui/material';
import regexifyString from 'regexify-string';
import isEmpty from 'lodash-es/isEmpty';

function VersionRender({
  identifier,
  fontStyle,
  templateValues,
  onlyChildren,
  labelFormat,
  children,
}) {
  const [formVersion] = useFieldValue(`version.formVersion`);
  const [startVersion] = useFieldValue(`version.${identifier}.startVersion`);
  const [endVersion] = useFieldValue(`version.${identifier}.endVersion`);
  const [fieldText] = useFieldValue(`version.${identifier}.fieldText`);

  function replaceTemplateVars({ text: textProp }) {
    const pattern = /{{\s*(\w+?)\s*}}/g; // {property}
    return textProp.replace(pattern, (_, token) => {
      if (templateValues[token]) {
        return templateValues[token]?.value;
      }
      return '';
    });
  }

  const addFormatting = ({ format, text: textProp }) => {
    switch (format) {
      case 'bold': {
        return <b>{textProp}</b>;
      }
      case 'italic': {
        return <i>{textProp}</i>;
      }
      case 'spaces': {
        const spacesToAdd = Number(textProp);
        return <span style={{ marginLeft: `${spacesToAdd}px` }} />;
      }
      case 'break': {
        return <br />;
      }
      case 'color': {
        const newTextProp = textProp.split(' ');
        const colorWord = textProp.split(' ')[0];
        newTextProp.shift();

        return (
          <span style={{ color: colorWord }}>{newTextProp.join(' ')}</span>
        );
      }
      default:
        return textProp;
    }
  };

  const addResults = (textInput) =>
    regexifyString({
      pattern: /\{{.*?\}}/gim,
      decorator: (match) => {
        const nameOfField = match.replace(/[{{}}']+/g, '');
        const firstWord = nameOfField.split(' ')[0];
        if (
          ['bold', 'italic', 'spaces', 'color', 'break'].includes(firstWord)
        ) {
          const newTextArray = nameOfField.split(' ');
          newTextArray.shift();

          return addFormatting({
            format: firstWord,
            text: newTextArray.join(' '),
          });
        }

        const foundTemplate = templateValues[nameOfField];
        if (foundTemplate?.component) {
          return foundTemplate.component;
        }

        if (foundTemplate?.value) {
          return (
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              {foundTemplate.value.trim() !== '' ? foundTemplate.value : <br />}
              <span
                style={{
                  borderTop: '1px solid black',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                {foundTemplate.label}
              </span>
            </span>
          );
        }

        return '';
      },
      input: textInput,
    });

  function FormattedText({ text: textProp, ...rest }) {
    const text = textProp;
    return (
      <Box
        display={labelFormat ? undefined : 'flex'}
        flexDirection="column"
      >
        {text &&
          text.split('\n').map((line, index) => {
            const leadingTabs = Math.round(line.search(/\S|$/) / 2); // 2 spaces per tab
            return (
              <Typography
                {...rest}
                style={{
                  display: labelFormat ? undefined : 'flex',
                  flexDirection: 'column',
                  marginLeft: labelFormat ? undefined : `${leadingTabs}em`,
                  fontSize: labelFormat ? 'inherit' : undefined,
                  ...fontStyle,
                }}
                key={index}
              >
                {/* @TODO: Fix this nested madness */}
                {/* eslint-disable-next-line */}
                {templateValues ? (
                  !isEmpty(templateValues) ? (
                    <div
                      style={{
                        display: labelFormat ? undefined : 'flex',
                        flexWrap: 'wrap',
                        gridGap: '5px',
                      }}
                    >
                      {addResults(line).map((item, i) => (
                        <React.Fragment key={i}>{item}</React.Fragment>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {addResults(line).map((item, i) => (
                        <React.Fragment key={i}> {item} </React.Fragment>
                      ))}
                    </div>
                  )
                ) : (
                  line
                )}
              </Typography>
            );
          })}
      </Box>
    );
  }
  if (
    startVersion !== undefined &&
    (startVersion === null || startVersion <= formVersion) &&
    (endVersion === null || formVersion <= endVersion)
  ) {
    if (fieldText && onlyChildren !== true) {
      if (templateValues) {
        return children({
          fieldText: replaceTemplateVars({
            text: fieldText,
          }),
          renderText: <FormattedText text={fieldText} />,
        });
      }

      return children({
        fieldText,
        renderText: <FormattedText text={fieldText} />,
      });
    }
    return children;
  }
  return <> </>;
}
VersionRender.propTypes = {
  identifier: PropTypes.string,
  fontStyle: PropTypes.object,
  templateValues: PropTypes.object,
  children: PropTypes.node,
  onlyChildren: PropTypes.bool,
  labelFormat: PropTypes.bool,
};

VersionRender.defaultProps = {
  fontStyle: {},
  templateValues: {},
  onlyChildren: false,
  labelFormat: false,
};

export default VersionRender;
